import * as React from "react";
import {Translate} from "react-localize-redux";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {DateTime} from "luxon";
import {Dictionary, Project, ProjectTime, TParams} from "../../Models";
import {
	Container, Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField
} from "@material-ui/core";
import {BootstrapContextProps} from "../../Components/Bootstrap/BootstrapContext";
import {convertFromFirestoreToDocuments, firestoreDb} from "../../Components/Firebase";
import {withBootstrap} from "../../Components/Bootstrap/withBootstrap";
import TemplateHelper from "../../Components/Common/TemplateHelper";
import LoadingIndicator from "../../Components/Common/LoadingIndicator";
import LocaleLink from "../../Components/Common/LocaleLink";

interface InterfaceProps {
	id: string,
}

interface TimeSheetData {
	startTime: string,
	endTime: string,
	totalDuration: number,
}

interface TimeSheet {
	comment: string,
	startTime: DateTime,
	endTime: DateTime,
	duration: number,
	totalDuration: number,
	pauseDuration: number,
}

interface TimeSheetDictionary {
	[key: string]: TimeSheet,
}

interface InterfaceState {
	current: TimeSheetData,
	errors: Dictionary,
	loading: boolean,
	project: Project,
	projectSlug: string,
	timeSheets: TimeSheetDictionary,
}

class ProtectedTimeTrackingTimeSheets extends React.Component<BootstrapContextProps & InterfaceProps & RouteComponentProps<TParams>, InterfaceState> {
	state = {
		current: {
			startTime: DateTime.local().startOf('month').toFormat('yyyy-MM-dd'),
			endTime: DateTime.local().endOf('month').toFormat('yyyy-MM-dd'),
			totalDuration: 0,
		},
		errors: {} as Dictionary,
		project: {} as Project,
		projectSlug: '',
		loading: true,
		timeSheets: {} as TimeSheetDictionary,
	}

	constructor(props: BootstrapContextProps & InterfaceProps & RouteComponentProps<TParams>) {
		super(props);

		this.loadData = this.loadData.bind(this);
	}

	loadData() {
		const {current} = this.state;
		const slug = this.props.match.params.slug
		if (!slug || !current) {
			return;
		}

		this.setState({loading: true})
		firestoreDb.collection(`project-times`)
			.where('user.uid', '==', this.props.user?.uid)
			.where('projectSlug', '==', slug)
			.where('createdAt', '>=', current.startTime)
			.where('createdAt', '<=', current.endTime)
			.get()
			.then(result => {
				const docs = result.empty ? [] : convertFromFirestoreToDocuments(result.docs)
				console.log('Retrieved documents', docs)
				this.calculateTimes(docs as any[], slug)
			})
	}

	calculateTimes(projectTimes: ProjectTime[], slug: string) {
		const timeSheets = {} as TimeSheetDictionary

		for (const projectTime of projectTimes) {
			// Skip if it is currently running (not ended)
			if (!projectTime.endTime) {
				continue
			}

			const sheetIdx = DateTime.fromISO(projectTime.startTime).startOf('day').toISO()
			const current = timeSheets[sheetIdx] ? timeSheets[sheetIdx] : {} as TimeSheet

			const projectTimeStartTime = DateTime.fromISO(projectTime.startTime).startOf('minute')
			const projectTimeEndTime = DateTime.fromISO(projectTime.endTime).endOf('minute')
			const duration = projectTimeEndTime.toSeconds() - projectTimeStartTime.toSeconds()

			current.comment = !current.comment ? projectTime.comment : current.comment
			current.startTime = !current.startTime || current.startTime > projectTimeStartTime ? projectTimeStartTime : current.startTime
			current.endTime = !current.endTime || current.endTime < projectTimeEndTime ? projectTimeEndTime : current.endTime
			current.totalDuration = current.totalDuration ? current.totalDuration + duration : duration

			timeSheets[sheetIdx] = current
		}

		console.log('calculateTimes', timeSheets)

		let startTime = DateTime.fromISO('3000-01-01')
		let endTime = DateTime.fromISO('1900-01-01')
		let totalDuration = 0
		for (const idx of Object.keys(timeSheets)) {
			const timeSheet = timeSheets[idx]
			timeSheet.pauseDuration = Math.round(Math.abs(timeSheet.endTime.toSeconds() - timeSheet.startTime.toSeconds() - timeSheet.duration) / 60)
			timeSheet.totalDuration = timeSheet.totalDuration / 60
			totalDuration += timeSheet.totalDuration

			startTime = startTime > timeSheet.startTime ? timeSheet.startTime : startTime
			endTime = endTime < timeSheet.endTime ? timeSheet.endTime : endTime
		}

		const currentData = {
			startTime: startTime.toISO(),
			endTime: endTime.toISO(),
			totalDuration: totalDuration,
		} as TimeSheetData
		console.log('calculateTimes', timeSheets, totalDuration)

		this.setState({timeSheets: timeSheets, loading: false, projectSlug: slug, current: currentData})
	}

	componentDidMount(): void {
		this.loadData();
	}

	componentDidUpdate(prevProps: Readonly<BootstrapContextProps & InterfaceProps & RouteComponentProps<TParams>>) {
		if (prevProps.match.params.slug !== this.props.match.params.slug) {
			this.loadData();
		}
	}

	render(): JSX.Element {
		const {current, project, timeSheets} = this.state;

		if (this.state.loading) {
			return <LoadingIndicator/>
		}

		return (
			<div className="wrapper">
				<div className="main">
					<div className="section section-white">
						<Container>
							<Grid container={true}>
								<Grid item={true} className="ml-auto mr-auto text-center title" md={12}>
									<h1 className="hide-print">
										<Translate id="Timesheet"/>
									</h1>

									<ul className="navigation hide-print">
										<li>
											<LocaleLink to="protected/time-tracking">
												<Translate id="Time Tracking"/>
											</LocaleLink>
										</li>
										<li>
											<LocaleLink to="protected/time-tracking/show">
												<Translate id="Timesheet"/>
											</LocaleLink>
										</li>
									</ul>

									<div className="select-time-span hide-print">
										<Translate id="time-from"/>:
										<TextField type="date"
															 value={TemplateHelper.formatDate(current.startTime, 'isoDate')}
															 onChange={(val) =>
																 TemplateHelper.updateNestedSelectField(this, 'current', 'startTime', val.target.value)}
															 InputLabelProps={{shrink: true}}/>

										<Translate id="time-to"/>:
										<TextField type="date"
															 value={TemplateHelper.formatDate(current.endTime, 'isoDate')}
															 onChange={(val) =>
																 TemplateHelper.updateNestedSelectField(this, 'current', 'endTime', val.target.value)}
															 InputLabelProps={{shrink: true}}/>
										<button onClick={this.loadData}>
											<Translate id="Set"/>
										</button>
									</div>
									<br/>

									<div className="project-info">
										<TableContainer>
											<Table style={{width: '80%'}}>
												<TableBody>
													<TableRow>
														<TableCell>
															<Translate id="Period of performance"/>
														</TableCell>
														<TableCell>
															{TemplateHelper.formatDate(project.startTime, 'shortDate')}
															&nbsp;-&nbsp;
															{TemplateHelper.formatDate(project.endTime, 'dd.MM.yy')}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															<Translate id="Project"/>
														</TableCell>
														<TableCell>
															{project.title}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															<Translate id="Contract Number"/>
														</TableCell>
														<TableCell>
															Projekteinzelvertrag Nr. 1013145
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															<Translate id="External employee"/>
														</TableCell>
														<TableCell>
															Mike Petersen
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</TableContainer>
									</div>
									<br/>

									<TableContainer>
										<Table className="table table-condensed">
											<TableHead>
												<TableRow>
													<TableCell rowSpan={2}>
														<Translate id="date"/>
													</TableCell>
													<TableCell colSpan={2} style={{borderBottom: 'none', textAlign: 'center'}}>
														<Translate id="Time of day"/>
													</TableCell>
													<TableCell rowSpan={2}>
														<Translate id="Pause"/>
													</TableCell>
													<TableCell rowSpan={2}>
														<Translate id="Duration"/>
													</TableCell>
													<TableCell rowSpan={2}>
														<Translate id="Comment"/>
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell style={{borderTop: 'none'}}>
														<Translate id="time-from"/>
													</TableCell>
													<TableCell style={{borderTop: 'none'}}>
														<Translate id="time-to"/>
													</TableCell>
												</TableRow>
											</TableHead>

											<TableBody>
												{timeSheets && Object.keys(timeSheets).sort().reverse().map((timeSheetIdx, idx) => {
													const timeSheet = timeSheets[timeSheetIdx]
													return (
														<tr key={idx}>
															<TableCell>{timeSheet.startTime.toLocaleString(DateTime.DATE_SHORT)}</TableCell>
															<TableCell>{timeSheet.startTime.toFormat('HH:mm')}</TableCell>
															<TableCell>{timeSheet.endTime.toFormat('HH:mm')}</TableCell>
															<TableCell>{TemplateHelper.formattedDuration(timeSheet.pauseDuration)}</TableCell>
															<TableCell>{TemplateHelper.formattedDuration(timeSheet.totalDuration)}</TableCell>
															<TableCell>{timeSheet.comment}</TableCell>
														</tr>
													)
												})}
												<TableRow>
													<TableCell colSpan={4}>
														<Translate id="Total Hours"/>
													</TableCell>
													<TableCell>
														{TemplateHelper.formattedDuration(`${this.state.current.totalDuration}`)}
													</TableCell>
													<TableCell>&nbsp;</TableCell>
												</TableRow>
												<TableRow className="hide-print">
													<TableCell colSpan={4}>
														<Translate id="Total Hours on Project"/>
													</TableCell>
													<TableCell>
														{TemplateHelper.formattedDuration(project.totalDuration)}
													</TableCell>
													<TableCell>&nbsp;</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>

									<TableContainer>
										<Table style={{marginTop: '100px'}} className="table show-print">
											<TableBody>
												<TableRow>
													<TableCell style={{borderTop: '2px solid grey', borderSpacing: '2px', width: '500px'}}>
														<Translate id="sign-field-data"/>
													</TableCell>
													<TableCell style={{width: '40px', borderTop: 'none'}}>
														&nbsp;
													</TableCell>
													<TableCell style={{borderTop: '2px solid grey', borderSpacing: '2px', width: '500px'}}>
														<Translate id="sign-field-data-customer"/>
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</Grid>
						</Container>
					</div>
				</div>
			</div>
		)
	}
}

export default withBootstrap(withRouter(ProtectedTimeTrackingTimeSheets))

