import * as React from "react";
import Editor from 'draft-js-plugins-editor';
import PluginEditor from 'draft-js-plugins-editor';
import 'draft-js/dist/Draft.css';
import 'draft-js-counter-plugin/lib/plugin.css';
import 'draft-js-hashtag-plugin/lib/plugin.css';
import 'draft-js-anchor-plugin/lib/plugin.css';
import 'draft-js-linkify-plugin/lib/plugin.css';
import 'draft-js-side-toolbar-plugin/lib/plugin.css';
import 'draft-js-image-plugin/lib/plugin.css';
import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import 'draft-js-undo-plugin/lib/plugin.css';
import 'draft-js-video-plugin/lib/plugin.css';

import createCounterPlugin from 'draft-js-counter-plugin';
import createImagePlugin from 'draft-js-image-plugin';
import createSideToolbarPlugin from 'draft-js-side-toolbar-plugin';
import createUndoPlugin from 'draft-js-undo-plugin';
import createHashtagPlugin from 'draft-js-hashtag-plugin';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import createVideoPlugin from 'draft-js-video-plugin';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import {ContentState, EditorState} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import {Translate} from "react-localize-redux";

const counterPlugin = createCounterPlugin();
const {CharCounter, WordCounter, LineCounter} = counterPlugin;
const imagePlugin = createImagePlugin();
const sideToolbarPlugin = createSideToolbarPlugin();
const {SideToolbar} = sideToolbarPlugin;
const hashtagPlugin = createHashtagPlugin();
const linkifyPlugin = createLinkifyPlugin();
const toolbarPlugin = createToolbarPlugin();
const {Toolbar} = toolbarPlugin;
const undoPlugin = createUndoPlugin();
const videoPlugin = createVideoPlugin();

const plugins = [
	counterPlugin,
	imagePlugin,
	linkifyPlugin,
	hashtagPlugin,
	sideToolbarPlugin,
	toolbarPlugin,
	undoPlugin,
	videoPlugin,
];

export interface OdaniaEditorState {
	editorState: EditorState,
}

export interface OdaniaEditorProps {
	content: string,
	updateContent: (content: string) => void,
}

class OdaniaEditor extends React.Component<OdaniaEditorProps, OdaniaEditorState> {
	private editorRef = React.createRef<PluginEditor>()

	state = {
		editorState: EditorState.createWithContent(ContentState.createFromText(this.props.content || '')),
	}

	constructor(props: OdaniaEditorProps) {
		super(props);

		this.focusEditor = this.focusEditor.bind(this);
		this.onChange = this.onChange.bind(this);
		this.setState = this.setState.bind(this);
	}

	componentDidMount(): void {
		const contentState = stateFromHTML(this.props.content);
		this.setState({editorState: EditorState.createWithContent(contentState, undefined)});
	}

	onChange(editorState: EditorState): void {
		this.setState({editorState: editorState})
		this.props.updateContent(stateToHTML(editorState.getCurrentContent()))
	}

	focusEditor(): void {
		if (!this.editorRef) {
			return
		}

		this.editorRef.current?.focus()
	}

	render() {
		return (
			<>
				<Toolbar/>
				<div onClick={this.focusEditor} style={styles.editor}>
					<Editor
						ref={this.editorRef}
						editorState={this.state.editorState}
						onChange={this.onChange}
						plugins={plugins}
					/>
					<SideToolbar/>
				</div>

				<span>
					<CharCounter editorState={this.state.editorState}/> <Translate id="general.characters"/>
					&nbsp;&nbsp;
					<WordCounter editorState={this.state.editorState}/> <Translate id="general.words"/>
					&nbsp;&nbsp;
					<LineCounter editorState={this.state.editorState}/> <Translate id="general.lines"/>
				</span>
			</>
		)
	}
}

const styles = {
	editor: {
		background: '#fefefe',
		border: '1px solid #ddd',
		borderRadius: '2px',
		boxShadow: 'inset 0px 1px 8px -3px #ababab',
		cursor: 'text',
		marginBottom: '2em',
		minHeight: '6em',
		padding: '16px',
	}
};

export default OdaniaEditor
