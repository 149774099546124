import * as React from "react";
import {Translate} from "react-localize-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import InnerHTML from "dangerously-set-html-content";
import {Clipping, Dictionary, TParams} from "../../Models";
import {Container, Grid} from "@material-ui/core";
import {BootstrapContextProps} from "../../Components/Bootstrap/BootstrapContext";
import {convertFromFirestoreToDocuments, firestoreDb} from "../../Components/Firebase";
import TemplateHelper from "../../Components/Common/TemplateHelper";
import Bookmarklet from "../../Components/Clippings/Bookmarklet";
import {withBootstrap} from "../../Components/Bootstrap/withBootstrap";
import LocaleLink from "../../Components/Common/LocaleLink";

interface InterfaceState {
	clipping: Clipping | undefined,
	clippings: Clipping[],
	errors: Dictionary,
	loading: boolean,
}

class ProtectedClippingsIndex extends React.Component<BootstrapContextProps & RouteComponentProps<TParams>, InterfaceState> {
	state = {
		clippings: [] as Clipping[],
		errors: {},
		clipping: undefined as Clipping | undefined,
		loading: true,
	}

	loadData() {
		this.setState({loading: true})
		firestoreDb
			.collection(`clippings`)
			.where('user.uid', '==', this.props.user?.uid)
			.orderBy('createdAt', 'desc')
			.limit(20)
			.get()
			.then(result => {
				const docs = result.empty ? [] : convertFromFirestoreToDocuments(result.docs)
				console.log('Loaded clippings', docs)
				this.setState({clippings: docs as Clipping[], loading: false})
			})
	}

	componentDidMount(): void {
		this.loadData()
	}

	render(): JSX.Element {
		const currentClipping = this.state.clipping

		return (
			<div className="wrapper">
				<div className="main">
					<div className="section section-white">
						<Container>
							<Grid container={true}>
								<Grid item={true} className="ml-auto mr-auto text-center title" md={6}>
									<h1>
										<Translate id="Clippings"/>
									</h1>

									<ul className="messagesList">
										{this.state.clippings && this.state.clippings.map((clipping, idx) => {
											return (
												<li key={idx}>
                              <span className="from">
                                <span className="glyphicons star"/>
																{clipping.user && clipping.user.name}
																<span className="glyphicons paperclip"/>
															</span>
													<span className="title">
																{clipping.tags}

														<a onClick={() => this.setState({clipping: clipping})}>
																	<i className="fa fa-eye"/> {clipping.title}
																</a>

																<LocaleLink to={`protected/clippings/${clipping.slug}`}>
																	<i className="fa fa-edit"/>
																</LocaleLink>

																<a href={clipping.url} target="_blank" rel="nofollow noreferrer">
																	<i className="fa fa-external-link"/>
																</a>
															</span>
													<span className="date">
																{TemplateHelper.formatDate(clipping.createdAt)}
                               </span>
												</li>
											)
										})}
									</ul>
								</Grid>
								<Grid item={true} className="ml-auto mr-auto text-center title" md={6}>
									<div className="message dark">
										{currentClipping &&
										<div>
											<div className="header">
												<h1>{currentClipping.title}</h1>

												<div className="from">
													<i className="halflings-icon user"/>
													<b>{currentClipping.user && currentClipping.user.name}</b>
												</div>
												<div className="date">
													<i
														className="halflings-icon time"/> {TemplateHelper.formatDate(currentClipping.createdAt)}
												</div>
											</div>

											<div className="content">
												<a href={currentClipping.url} target="_blank" rel="nofollow noreferrer">
													<i className="fa fa-external-link"/>
												</a>

												<InnerHTML html={currentClipping.description}/>
											</div>
										</div>
										}

										<Bookmarklet/>
									</div>
								</Grid>
							</Grid>
						</Container>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(withBootstrap(ProtectedClippingsIndex))
