import * as React from "react";
import update from "immutability-helper";
import {DateTime, DateTimeFormatOptions, Duration, LocaleOptions} from "luxon";
import {Translate} from "react-localize-redux";
import {Dictionary} from "../../Models";

class TemplateHelper {
	public static classForTab(currentTab: string, tabName: string): string {
		if (currentTab === tabName) {
			return "nav-link active";
		}

		return "nav-link";
	}

	public static detectElement(needle: string | undefined, haystack: any[], lookUpKey = 'slug'): any {
		if (!needle) {
			return null
		}

		for (const entry of haystack) {
			if (needle === entry[lookUpKey]) {
				return entry
			}
		}

		return null
	}

	public static currentDateTime(): string {
		return DateTime.local().toISO()
	}

	public static formatDate(date: string, dateType = 'medium'): string {
		if (!date) {
			return "-";
		}

		let dateFormat: LocaleOptions & DateTimeFormatOptions;
		switch (dateType) {
			case 'date':
				dateFormat = DateTime.DATE_MED;
				break;
			case 'isoDate':
				return DateTime.fromISO(date).toFormat('yyyy-MM-dd');
			case 'medium':
				dateFormat = DateTime.DATETIME_MED;
				break;
			case 'full':
				dateFormat = DateTime.DATETIME_FULL;
				break;
			case 'time':
				dateFormat = DateTime.TIME_24_SIMPLE;
				break;
			case 'iso':
				return DateTime.fromISO(date).toFormat("yyyy-MM-dd'T'T");
			default:
				dateFormat = DateTime.DATETIME_FULL;
		}

		return DateTime.fromISO(date).toLocaleString(dateFormat);
	}

	public static formattedDuration(duration: string | number | undefined): string {
		if (!duration) {
			return "-"
		}

		const minutes = typeof duration === 'string' ? parseInt(duration) : duration
		const formattedDuration = Duration.fromObject({minutes: minutes}).toFormat('hh:mm')
		return `${formattedDuration} [${duration}]`
	}

	public static handleSubmit(event: any): void {
		event.preventDefault();
	}

	public static limitTo(str: string, length: number): string {
		if (!str) {
			return '';
		}

		return str.substr(0, length);
	}

	public static updateField(that: any, event: any): void {
		that.setState({[event.target.name]: event.target.value});
	}

	public static updateFieldWithValue(that: any, key: string, value: any): void {
		that.setState({[key]: value});
	}

	public static updateNestedSelectField(that: any, primaryKey: string, key: string, val: any): void {
		const newData = update(that.state[primaryKey], {
			[key]: {
				$set: val
			}
		});
		that.setState({[primaryKey]: newData});
	}

	public static fromSelectOptions(data: any[]): string[] {
		const result = [];
		for (const key of data) {
			result.push(key.value);
		}

		return result;
	}

	public static hasErrors(errors: Dictionary): boolean {
		for (const error of Object.keys(errors)) {
			const val = errors[error]

			if (val) {
				return true
			}
		}

		return false
	}

	public static printErrorFor(errors: Dictionary, key: string) {
		if (errors[key]) {
			return (
				<div className="alert alert-danger">
					<Translate id={errors[key]} />
				</div>
			)
		}

		return "";
	}

	public static toSelectOptions(data: string[]): any[] {
		const result = [];
		for (const key of data) {
			result.push({value: key, label: key});
		}

		return result;
	}

	public static updateError(that: any, key: string, msg: string | null): void {
		const newData = update(that.state.errors, {
			[key]: {$set: msg},
		})
		that.setState({errors: newData})
	}
}

export default TemplateHelper
