export const translations = {
	'About us': 'About us',
	'Add': 'Add',
	'Add content part': 'Add Content Part',
	'Add locale': 'Add Locale',
	'Accept': 'Accept',
	'Align': 'Align',
	'Allow Robot': 'Allow Robot',
	'Asset': 'Asset',
	'Asset Library': 'Asset Library',
	'Assets': 'Assets',
	'Body': 'Body',
	'Cancel': 'Cancel',
	'Categories': 'Categories',
	'Category': 'Category',
	'Clippings': 'Clippings',
	'Comment': 'Comment',
	'Contact us': 'Contact us',
	'Contact form': 'Contact form',
	'Content Part Type': 'Content Part Type',
	'Content Visibility': 'Content Visibility',
	'Contract Number': 'Contract Number',
	'Copyright': 'Copyright',
	'Creator': 'Creator',
	'date': 'Date',
	'Data Protection': 'Data Protection',
	'Description': 'Description',
	'Domain': 'Domain',
	'Domains': 'Domains',
	'Duration': 'Duration',
	'Edit': 'Edit',
	'Edit Asset': 'Edit Asset',
	'Edit Page': 'Edit Page',
	'Edit Post': 'Edit Post',
	'Email address': 'Email address',
	'External employee': 'External employee',
	'Footer Links': 'Footer Links',
	'German': 'German',
	'general.characters': 'Characters',
	'general.lines': 'Lines',
	'general.words': 'Words',
	'general.Please correct the errors': 'Please correct the errors',
	'Group Info': 'Group Info',
	'Home Body': 'Startpage Body',
	'Home Title': 'Startpage Title',
	'Icon': 'Icon',
	'Image': 'Image',
	'Image Copyright': 'Image Copyright',
	'Imprint': 'Imprint',
	'Language': 'Language',
	'Last': 'Last',
	'Latests Posts': 'Latests Posts',
	'Locale': 'Locale',
	'Loading': 'Loading...',
	'Logout': 'Logout',
	'Member': 'Member',
	'Members': 'Members',
	'Messages': 'Messages',
	'Next': 'Next',
	'No': 'No',
	'Pause': 'Pause',
	'Period': 'Period',
	'Period of performance': 'Period of performance',
	'Pages': 'Pages',
	'Phone': 'Phone',
	'Please wait': 'Please wait',
	'Post your comment': 'Post your comment',
	'Posts': 'Posts',
	'Previous': 'Previous',
	'Preview': 'Preview',
	'Profile': 'Profile',
	'Project': 'Project',
	'Read Article': 'Read Article',
	'Read more': 'Read More',
	'Save': 'Save',
	'Select': 'Select',
	'Set': 'Set',
	'Send us a message': 'Send us a message',
	'Settings': 'Settings',
	'Show': 'Show',
	'Show Posts on Home Page': 'Show Posts on Home Page',
	'sign-field-data': 'sign-field-data',
	'sign-field-data-customer': 'sign-field-data-customer',
	'Sites': 'Sites',
	'Slug': 'Slug',
	'Subtitle': 'Subtitle',
	'Success Journal': 'Success Journal',
	'Submit': 'Submit',
	'Tags': 'Tags',
	'The page you requested could not be found': 'The page you requested could not be found',
	'time-from': 'From',
	'time-to': 'To',
	'Timesheet': 'Timesheet',
	'Time Tracking': 'Time Tracking',
	'Time of day': 'Time of day',
	'Title': 'Title',
	'This page is under construction. Please come back later!': 'This page is under construction. Please come back later!',
	'Toggle Asset Library': 'Toggle Asset Library',
	'Total Hours': 'Total Hours',
	'Total Hours on Project': 'Total Hours on Project',
	'Visibility': 'Visibility',
	'Welcome': 'Welcome',
	'Welcome Admin': 'Welcome Admin',
	'We use cookies to enhance your experience.': 'We use cookies to enhance your experience.',
	'Wishes and goals': 'Wishes and goals',
	'Yes': 'Yes',
	'You can contact us if you have any questions or suggestions. All Feedback Welcome.': 'You can contact us if you have any questions or suggestions. All Feedback Welcome.',
	'You can discover': 'You can discover',
	'Your message': 'Your message',
	'Your Name': 'Your Name',
};
