import * as React from "react";
import {Translate} from "react-localize-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Dictionary, Project, TParams} from "../../Models";
import {Button, Container, Grid} from "@material-ui/core";
import {BootstrapContextProps} from "../../Components/Bootstrap/BootstrapContext";
import {firestoreDb} from "../../Components/Firebase";
import {withBootstrap} from "../../Components/Bootstrap/withBootstrap";
import TemplateHelper from "../../Components/Common/TemplateHelper";
import PrintError from "../../Components/Common/PrintError";
import OdaniaEditor from "../../Components/Editor/OdaniaEditor";
import {slugify} from "../../Components/Common/Utils";

interface InterfaceProps {
	id: string,
}

interface InterfaceState {
	errors: Dictionary,
	loading: boolean,
	project: Project,
}

class ProtectedProjectsEdit extends React.Component<BootstrapContextProps & InterfaceProps & RouteComponentProps<TParams>, InterfaceState> {
	state = {
		errors: {} as Dictionary,
		project: {} as Project,
		loading: true,
	};

	constructor(props: any) {
		super(props);

		this.saveProject = this.saveProject.bind(this);
	}

	loadData() {
		if (this.props.match.params.slug === 'new') {
			this.setState({loading: false})
			return
		}

		this.setState({loading: true})
		const slug = this.props.match.params.slug
		firestoreDb.collection(`projects`)
			.doc(slug)
			.get()
			.then(result => {
				const doc = result.exists ? result.data() : {} as Project
				console.log('Retrieved document from firestore', doc)
				this.setState({loading: false, project: doc as any})
			})
			.catch(err => {
				console.error('loadData', err)
			})
	}

	componentDidMount(): void {
		this.loadData();
	}

	componentDidUpdate(prevProps: Readonly<BootstrapContextProps & InterfaceProps>, prevState: Readonly<InterfaceState>, snapshot?: any): void {
		if (prevProps.id !== this.props.id) {
			this.loadData();
		}
	}

	saveProject() {
		const {project} = this.state
		const slug = slugify(`${project.locale}-${project.title}`)
		project.slug = project.slug ? project.slug : slug
		firestoreDb.collection(`projects`)
			.doc(this.props.match.params.slug)
			.set(project)
			.then(() => {
				console.log('Saved')
			})
	}

	render(): JSX.Element {
		const {project} = this.state;

		return (
			<div className="wrapper">
				<div className="main">
					<div className="section section-white">
						<Container>
							<Grid container={true}>
								<Grid item={true} className="ml-auto mr-auto text-center title" md={12}>
									<form onSubmit={TemplateHelper.handleSubmit} className="form-horizontal" role="form">
										<div className="row-fluid">
											<div className="col-md-9">
												<h1>Editing Project</h1>

												<div className="form-group">
													<label className="col-sm-2 control-label">Title</label>

													<div className="col-sm-10">
														<input type="text" value={project.title} placeholder="Title" className="form-control"/>
														<PrintError errors={this.state.errors['title']}/>
													</div>
												</div>

												<div className="form-group">
													<label className="col-sm-2 control-label">Description</label>

													<div className="col-sm-10">
														<OdaniaEditor content={project.description}
																					updateContent={(content) => TemplateHelper.updateNestedSelectField(this, 'project', 'description', content)}/>
														<PrintError errors={this.state.errors['description']}/>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												{Object.keys(this.state.errors).length === 0 &&
												<span style={{color: 'red'}}><Translate id="general.Please correct the errors"/></span>}

												<div className="well">
													<h3><Translate id="Title"/></h3>

													<Button onClick={this.saveProject} className="btn btn-primary">
														<Translate id="Save"/>
													</Button>
												</div>

												<div className="form-group">
													<label className="col-sm-2 control-label">Active</label>

													<div className="col-sm-10">
														<input type="checkbox" value={"project.active"}/>
													</div>
												</div>
											</div>
										</div>
									</form>
								</Grid>
							</Grid>
						</Container>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(withBootstrap(ProtectedProjectsEdit))
