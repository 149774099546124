import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';

// Configure Firebase.
import {firebaseConfig} from "../../config";

firebase.initializeApp(firebaseConfig)
firebase.analytics()

export const firebaseAuth = firebase.auth()
export const firestoreDb = firebase.firestore()

// Configure FirebaseUI.
export const firebaseUiConfig = {
	// Popup signin flow rather than redirect flow.
	signInFlow: 'popup',
	// Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
	signInSuccessUrl: '/signedIn',
	// We will display Google and Facebook as auth providers.
	signInOptions: [
		firebase.auth.GoogleAuthProvider.PROVIDER_ID,
		firebase.auth.FacebookAuthProvider.PROVIDER_ID,
		{
			provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
			requireDisplayName: true,
		},
	]
}

export const convertFromFirestoreToDocuments = (documents: firebase.firestore.QueryDocumentSnapshot[]): firebase.firestore.DocumentData[] => {
	const result = [] as firebase.firestore.DocumentData[]
	for (const doc of documents) {
		result.push(doc.data())
	}

	return result
}
