export const translations = {
	'About us': 'Über uns',
	'Add': 'Hinzufügen',
	'Add content part': 'ContentPart hinzufügen',
	'Add locale': 'Sprache hinzufügen',
	'Accept': 'Akzeptieren',
	'Align': 'Align',
	'Allow Robot': 'Robot erlauben',
	'Asset': 'Asset',
	'Asset Library': 'Asset Bibliothek',
	'Assets': 'Assets',
	'Body': 'Text',
	'Cancel': 'Abbrechen',
	'Categories': 'Kategorien',
	'Category': 'Kategorie',
	'Clippings': 'Clippings',
	'Comment': 'Kommentar',
	'Contact us': 'Kontakt',
	'Contact form': 'Kontakformular',
	'Content Part Type': 'ContentPart Typ',
	'Content Visibility': 'Sichtbarkeit',
	'Contract Number': 'Vertragsnummer',
	'Copyright': 'Copyright',
	'Creator': 'Erstellt von',
	'date': 'Datum',
	'Data Protection': 'Datenschutz',
	'Description': 'Beschreibung',
	'Domain': 'Domain',
	'Domains': 'Domains',
	'Duration': 'Duration',
	'Edit': 'Bearbeiten',
	'Edit Asset': 'Asset bearbeiten',
	'Edit Page': 'Seite bearbeiten',
	'Edit Post': 'Beitrag bearbeiten',
	'Email address': 'Email Adresse',
	'External employee': 'External employee',
	'Footer Links': 'Footer Links',
	'German': 'Deutsch',
	'general.characters': 'Zeichen',
	'general.lines': 'Zeilen',
	'general.words': 'Wörter',
	'general.Please correct the errors': 'Bitte behebe diese Fehler',
	'Group Info': 'Gruppen Info',
	'Home Body': 'Startseiten Text',
	'Home Title': 'Startseiten Titel',
	'Icon': 'Icon',
	'Image': 'Bild',
	'Image Copyright': 'Bild Copyright',
	'Imprint': 'Impressum',
	'Language': 'Sprache',
	'Last': 'Vorige',
	'Latests Posts': 'Neueste Beiträge',
	'Locale': 'Sprache',
	'Loading': 'Loading...',
	'Logout': 'Abmelden',
	'Member': 'Mitglieder',
	'Members': 'Mitglieder',
	'Messages': 'Nachrichten',
	'Next': 'Nächste',
	'No': 'Nein',
	'Pause': 'Pause',
	'Pages': 'Seiten',
	'Period': 'Zeitraum',
	'Period of performance': 'Period of performance',
	'Phone': 'Telefon',
	'Please wait': 'Bitte warten',
	'Post your comment': 'Schreibe einen Kommentar',
	'Posts': 'Beiträge',
	'Previous': 'Vorige',
	'Preview': 'Vorschau',
	'Profile': 'Profil',
	'Project': 'Project',
	'Read Article': 'Artikel lesen',
	'Read more': 'Weiterlesen',
	'Save': 'Speichern',
	'Select': 'Auswählen',
	'Send us a message': 'Sende uns eine Nachricht',
	'Set': 'Asuwählen',
	'Settings': 'Einstellungen',
	'Show': 'Zeigen',
	'Show Posts on Home Page': 'Zeige Beiträge auf der Startseite',
	'sign-field-data': 'sign-field-data',
	'sign-field-data-customer': 'sign-field-data-customer',
	'Sites': 'Seiten',
	'Slug': 'Slug',
	'Subtitle': 'Subtitle',
	'Success Journal': 'Erfolgsjournal',
	'Submit': 'Senden',
	'Tags': 'Tags',
	'The page you requested could not be found': 'Die Seite wurde leider nichte gefunden',
	'time-from': 'Von',
	'time-to': 'Bis',
	'Timesheet': 'Timesheet',
	'Time Tracking': 'Zeiterfassung',
	'Time of day': 'Time of day',
	'Title': 'Titel',
	'Total Hours': 'Total Hours',
	'Total Hours on Project': 'Total Hours on Project',
	'This page is under construction. Please come back later!': 'Diese Seite befindet sich noch in der Entwicklung. Bitte komme später wieder!',
	'Toggle Asset Library': 'Toggle Asset Library',
	'Visibility': 'Sichtbarkeit',
	'Welcome': 'Willkommen',
	'Welcome Admin': 'Willkommen Admin',
	'We use cookies to enhance your experience.': 'Wir benutzen Cookies um deinen Besuch optimal zu gestalten.',
	'Wishes and goals': 'Wünsche und Ziele',
	'Yes': 'Ja',
	'You can contact us if you have any questions or suggestions. All Feedback Welcome.':
		'Du kannst uns gerne kontaktieren. Fragen, Vorschläge und Feedback ist Willkommen.',
	'You can discover': 'Schaue alternativ hier',
	'Your message': 'Deine Nachricht',
	'Your Name': 'Dein Name',
};
