import {Content} from "../../CommonModels";

export const getCategoryOrTagFor = (content: Content): string => {
	if (content.category) {
		return content.category
	}

	if (content.tags && content.tags[0]) {
		return content.tags[0]
	}

	return 'General'
}

export const slugify = (text: string): string => {
	return text
		.toString()                     // Cast to string
		.toLowerCase()                  // Convert the string to lowercase letters
		.normalize("NFD")       // The normalize() method returns the Unicode Normalization Form of a given string.
		.trim()                         // Remove whitespace from both sides of a string
		.replace(/\s+/g, '-')           // Replace spaces with -
		.replace(/[^\w-]+/g, '')       // Remove all non-word chars
		.replace(/--+/g, '-');        // Replace multiple - with single -
}
