import * as React from "react";

export interface InterfaceProps {
	errors: any,
}

class PrintError extends React.Component<InterfaceProps, any> {
	render() {
		if (this.props.errors) {
			return (
				<span style={{color: 'red'}}>
					{this.props.errors}
				</span>
			)
		}

		return ''
	}
}

export default PrintError
