import * as React from "react";
import {Translate} from "react-localize-redux";
import {Grid} from "@material-ui/core";

class LoadingIndicator extends React.Component<any, any> {
	render() {
		return (
			<div className="wrapper">
				<div className="main">
					<div className="section section-white">
							<Grid container={true} alignItems="center" alignContent="center" justify="center">
								<Grid item={true} className="ml-auto mr-auto text-center title" md={6}>
									<h2 className="title">
										<i className="fa fa-spinner"/>
										<span style={{paddingLeft: 24}}>
											<Translate id="Loading"/>
										</span>
									</h2>
								</Grid>
							</Grid>
					</div>
				</div>
			</div>
		)
	}
}

export default LoadingIndicator
