import * as React from "react";
import {Translate} from "react-localize-redux";
import update from "immutability-helper";
import {DateTime} from "luxon";
import {Dictionary, Project, ProjectTime} from "../../Models";
import {
	Container, Grid, Input, MenuItem, Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField
} from "@material-ui/core";
import {PlayArrow, Stop} from "@material-ui/icons";
import {BootstrapContextProps} from "../../Components/Bootstrap/BootstrapContext";
import {convertFromFirestoreToDocuments, firestoreDb} from "../../Components/Firebase";
import {withBootstrap} from "../../Components/Bootstrap/withBootstrap";
import TemplateHelper from "../../Components/Common/TemplateHelper";
import LoadingIndicator from "../../Components/Common/LoadingIndicator";
import LocaleLink from "../../Components/Common/LocaleLink";

interface InterfaceState {
	errors: Dictionary,
	loading: boolean,
	loadedProjects: boolean,
	loadedProjectTimes: boolean,
	current: ProjectTime | null,
	editItem: ProjectTime | undefined,
	projectSlug: string | undefined,
	projects: Project[],
	projectTimes: ProjectTime[],
}

class ProtectedTimeTrackingIndex extends React.Component<BootstrapContextProps, InterfaceState> {
	state = {
		errors: {} as Dictionary,
		loading: true,
		loadedProjects: false,
		loadedProjectTimes: false,
		current: null as ProjectTime | null,
		editItem: undefined as ProjectTime | undefined,
		projectSlug: undefined as string | undefined,
		projects: [] as Project[],
		projectTimes: [] as ProjectTime[],
	}

	constructor(props: BootstrapContextProps) {
		super(props)

		this.findProject = this.findProject.bind(this)
		this.startWork = this.startWork.bind(this)
		this.saveEditItem = this.saveEditItem.bind(this)
		this.saveItem = this.saveItem.bind(this)
		this.stopWork = this.stopWork.bind(this)
		this.updateEditItem = this.updateEditItem.bind(this)
		this.updateState = this.updateState.bind(this)
	}

	loadData() {
		this.setState({loading: true})
		firestoreDb
			.collection(`project-times`)
			.where('user.uid', '==', this.props.user?.uid)
			.orderBy('createdAt', 'desc')
			.limit(20)
			.get()
			.then(result => {
				const docs = result.empty ? [] : convertFromFirestoreToDocuments(result.docs)
				console.log('Loaded projectTimes', docs)
				this.updateState(docs)
			})

		firestoreDb
			.collection(`projects`)
			.where('user.uid', '==', this.props.user?.uid)
			.orderBy('title', 'asc')
			.get()
			.then(result => {
				const docs = result.empty ? [] : convertFromFirestoreToDocuments(result.docs)
				console.log('Loaded projects', docs)
				this.setState({projects: docs as any[], loading: false})
				this.updateLoadState(true, this.state.loadedProjectTimes)
			})
	}

	updateState(docs: any[]) {
		const lastProjectTime = docs.length > 0 && !docs[0].endTime ? docs[0] : null
		let projectSlug = this.state.projectSlug ? this.state.projectSlug : undefined
		if (!projectSlug) {
			projectSlug = docs.length > 0 ? docs[0].projectSlug : undefined
		}

		this.setState({
			current: lastProjectTime,
			projectSlug: projectSlug,
			projectTimes: docs,
		})
		this.updateLoadState(this.state.loadedProjects, true)
	}

	updateLoadState(loadedProjects: boolean, loadedProjectTimes: boolean) {
		const loadState = {
			loadedProjects: loadedProjects,
			loadedProjectTimes: loadedProjectTimes,
			loading: !(loadedProjects && loadedProjectTimes),
		}
		console.log('updateLoadState', loadState)
		this.setState(loadState)
	}

	componentDidMount(): void {
		this.loadData()
	}

	changeProject(projectSlug: string) {
		console.log('Change Project', projectSlug)
		this.setState({projectSlug: projectSlug})
	}

	editItem(item: ProjectTime | undefined) {
		this.setState({editItem: item})
	}

	saveItem(projectTime: ProjectTime) {
		console.log('Save item', projectTime)

		const idx = this.state.projectTimes.findIndex(obj => obj.slug === projectTime.slug)
		const newData = update(this.state.projectTimes, {
			[idx]: {$set: projectTime},
		})
		this.setState({projectTimes: newData})

		firestoreDb.collection(`project-times`)
			.doc(projectTime.slug)
			.set(projectTime)
			.then(() => {
				console.log('Saved')
			})
	}

	saveEditItem() {
		if (this.state.editItem) {
			const editItem = this.state.editItem
			editItem.updatedAt = TemplateHelper.currentDateTime()
			editItem.duration = (DateTime.fromISO(editItem.endTime).toSeconds() - DateTime.fromISO(editItem.startTime).toSeconds()) / 60
			this.saveItem(editItem)
			this.setState({editItem: undefined})
		}
	}

	startWork() {
		console.log('Start work', this.state.projectSlug)
		const slug = TemplateHelper.currentDateTime()
		const projectTime = {
			startTime: slug,
			projectSlug: this.state.projectSlug,
			slug: slug,
			createdAt: slug,
			updatedAt: slug,
			user: {
				uid: this.props.user?.uid,
			}
		} as ProjectTime

		const newData = update(this.state.projectTimes, {
			$unshift: [projectTime],
		})
		this.setState({current: projectTime, projectTimes: newData})

		firestoreDb.collection(`project-times`)
			.doc(slug)
			.set(projectTime)
			.then(() => {
				console.log('Saved')
			})
	}

	stopWork() {
		console.log('Stop work', this.state.projectSlug)

		const current = this.state.current
		if (!current) {
			console.error('Currently no running project time')
			return
		}

		current.endTime = TemplateHelper.currentDateTime()
		current.updatedAt = TemplateHelper.currentDateTime()
		current.duration = (DateTime.fromISO(current.endTime).toSeconds() - DateTime.fromISO(current.startTime).toSeconds()) / 60
		this.saveItem(current)
		this.setState({current: null})
	}

	findProject(slug: string): Project {
		const project = TemplateHelper.detectElement(slug, this.state.projects);

		if (!project) {
			return {title: `-UNKNONW- ${slug}`} as Project;
		}

		return project;
	}

	updateEditItem(key: string, val: string) {
		console.log("updateEditItem", key, val);
		const newEditItem = update(this.state.editItem, {
			[key]: {$set: val}
		});

		this.setState({editItem: newEditItem});
	}

	render(): JSX.Element {
		if (this.state.loading) {
			return <LoadingIndicator/>
		}

		const {current, projectTimes} = this.state;
		return (
			<div className="wrapper">
				<div className="main">
					<div className="section section-white">
						<Container>
							<Grid container={true}>
								<Grid className="ml-auto mr-auto text-center title" md={12}>
									<div className="time_tracking_box">
										{!current &&
										<div>
											<Select
														 value={this.state.projectSlug}
														 onChange={(element) => this.changeProject(element.target.value as string)}
											>
												<MenuItem/>
												{this.state.projects && this.state.projects.map((project, idx) => {
													return (
														<MenuItem key={idx} value={project.slug}>[{project.slug}] {project.title}</MenuItem>
													)
												})}
											</Select>

											<a onClick={this.startWork}>
												<PlayArrow style={{fontSize: '80px'}}/>
											</a>
										</div>
										}
										{current &&
										<div>
											Project: {this.findProject(current.projectSlug).title}
											<br/>
											<Translate id="advanced_todo.Start Time"/>
											{TemplateHelper.formatDate(current.startTime)}
											<br/>
											<a onClick={this.stopWork}>
												<Stop style={{fontSize: '80px'}}/>
											</a>
										</div>
										}

										{Object.keys(this.state.errors).length > 0 &&
										<span style={{color: 'red'}}><Translate id="general.Please correct the errors"/></span>}
									</div>

									<TableContainer>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell>
														<Translate id="Project"/>
													</TableCell>
													<TableCell>
														<Translate id="Duration"/>
													</TableCell>
													<TableCell>
														<Translate id="time-from"/>
													</TableCell>
													<TableCell>
														<Translate id="time-to"/>
													</TableCell>
													<TableCell>
														<Translate id="Comment"/>
													</TableCell>
													<TableCell>
														&nbsp;
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{projectTimes && projectTimes.map((projectTime, idx) => {
													if (this.state.editItem && this.state.editItem.slug === projectTime.slug) {
														return (
															<React.Fragment key={idx}>
																<TableRow>
																	<TableCell>{projectTime.projectSlug}</TableCell>
																	<TableCell>{TemplateHelper.formattedDuration(projectTime.duration)}</TableCell>
																	<TableCell colSpan={4}>
																		<button name="editItemId" onClick={this.saveEditItem}>
																			<Translate id="Save"/>
																		</button>
																		<button name="editItemId" onClick={() => this.editItem(undefined)}>
																			<Translate id="Cancel"/>
																		</button>
																	</TableCell>
																</TableRow>
																<TableRow>
																	<TableCell colSpan={2}>
																		{this.state.editItem.startTime}
																		<Input type="text"
																					 value={this.state.editItem.startTime}
																					 onChange={(event) => this.updateEditItem('startTime', event.target.value)}
																		/>
																	</TableCell>
																	<TableCell colSpan={2}>
																		<Input type="text"
																					 defaultValue={this.state.editItem.endTime}
																					 onChange={(event) => this.updateEditItem('endTime', event.target.value)}
																		/>
																	</TableCell>
																	<TableCell colSpan={3}>
																		<TextField name="comment" label={'Comment'} multiline={true}/>
																	</TableCell>
																</TableRow>
															</React.Fragment>
														)
													}

													return (
														<TableRow key={idx}>
															<TableCell>
																<LocaleLink to={`protected/time-tracking/${projectTime.projectSlug}`}>
																	{projectTime.projectSlug}
																</LocaleLink>
															</TableCell>
															<TableCell>{TemplateHelper.formattedDuration(projectTime.duration)}</TableCell>
															<TableCell>{TemplateHelper.formatDate(projectTime.startTime)}</TableCell>
															<TableCell>{TemplateHelper.formatDate(projectTime.endTime)}</TableCell>
															<TableCell data-toggle="tooltip" data-placement="top" title={projectTime.comment}>
																{TemplateHelper.limitTo(projectTime.comment, 20)}
															</TableCell>
															<TableCell>
																<button name="editItemId" onClick={() => this.editItem(projectTime)}>
																	<Translate id="Edit"/>
																</button>
															</TableCell>
														</TableRow>
													)
												})}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</Grid>
						</Container>
					</div>
				</div>
			</div>
		)
	}
}

export default withBootstrap(ProtectedTimeTrackingIndex)
