import React from "react";
import {Route, Switch} from "react-router-dom";
import {BootstrapContextProps} from "./Components/Bootstrap/BootstrapContext";
import {withBootstrap} from "./Components/Bootstrap/withBootstrap";
import LoginPage from "./Pages/Auth/LoginPage";
import ProtectedProjectsEdit from "./Pages/Projects/ProtectedProjectsEdit";
import ProtectedProjectsIndex from "./Pages/Projects/ProtectedProjectsIndex";
import ProtectedTimeTrackingTimeSheets from "./Pages/TimeTracking/ProtectedTimeTrackingTimeSheets";
import ProtectedTimeTrackingIndex from "./Pages/TimeTracking/ProtectedTimeTrackingIndex";
import NotFound from "./Pages/Errors/NotFound";
import RedirectToLocale from "./Components/Common/RedirectToLocale";
import ProtectedClippingsEdit from "./Pages/Clippings/ProtectedClippingsEdit";
import ProtectedClippingsIndex from "./Pages/Clippings/ProtectedClippingsIndex";
import LoadingIndicator from "./Components/Common/LoadingIndicator";
import Header from "./Components/Common/Header";

class RouterApp extends React.Component<BootstrapContextProps, any> {
	render() {
		if (!this.props.bootstrapFinished) {
			return <LoadingIndicator/>
		}

		if (!this.props.user) {
			return <LoginPage/>
		}

		return (
			<>
				<Header/>

				<Switch>
					<Route path="/:locale/protected/clippings/:slug" component={ProtectedClippingsEdit} exact={true}/>
					<Route path="/:locale/protected/clippings" component={ProtectedClippingsIndex} exact={true}/>
					<Route path="/:locale/protected/projects/:slug" component={ProtectedProjectsEdit} exact={true}/>
					<Route path="/:locale/protected/projects" component={ProtectedProjectsIndex} exact={true}/>
					<Route path="/:locale/protected/time-tracking/:slug" component={ProtectedTimeTrackingTimeSheets}
								 exact={true}/>
					<Route path="/:locale/protected/time-tracking" component={ProtectedTimeTrackingIndex} exact={true}/>

					<Route path="/:locale" component={LoginPage} exact={true}/>
					<Route path="/" component={RedirectToLocale} exact={true}/>

					<Route component={NotFound}/>
				</Switch>
			</>
		)
	}
}

export default withBootstrap(RouterApp)
