import React from "react";
import {BootstrapContextProps} from "../Bootstrap/BootstrapContext";
import {withBootstrap} from "../Bootstrap/withBootstrap";

class Bookmarklet extends React.Component<BootstrapContextProps, void> {
	render(): JSX.Element {
		return (
			<>
				<hr/>
				<div className="content">
					<div className="blockquote">
						<h3>Hilfe</h3>

						<p>Clippings lassen dich tolle Seiten aus dem Web bookmarken und teilen.</p>

						<p>Ziehe deinen Bookmarklet einfach in die Lesezeichleiste!</p>

						<p>
							Sobald du eine Interessante Seite/ein interessantes Bild hast klicke einfach auf das Lesezeichen um es in
							&quot;{this.props.pageTitle}&quot; zu speichern.</p>
					</div>
					<div>
						<h3>bookmarklet</h3>

						<p>Ziehe den Link in deinen Toolbar (Lesezeichenleiste)</p>

						<p>
							<a style={{border: '1px solig #ccc', background: '#efefef', padding: '5px'}}>
								{this.props.pageTitle}
							</a>
						</p>
					</div>
				</div>
			</>
		)
	}
}

export default withBootstrap(Bookmarklet)
