import * as React from "react";
import {Translate} from "react-localize-redux";
import {Link} from "react-router-dom";
import {Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {Dictionary, Project} from "../../Models";
import {BootstrapContextProps} from "../../Components/Bootstrap/BootstrapContext";
import {convertFromFirestoreToDocuments, firestoreDb} from "../../Components/Firebase";
import {withBootstrap} from "../../Components/Bootstrap/withBootstrap";
import LocaleLink from "../../Components/Common/LocaleLink";
import LoadingIndicator from "../../Components/Common/LoadingIndicator";

interface InterfaceState {
	errors: Dictionary,
	loading: boolean,
	projects: Project[],
}

class ProtectedProjectsIndex extends React.Component<BootstrapContextProps, InterfaceState> {
	state = {
		errors: {},
		projects: [] as Project[],
		loading: true,
	};

	componentDidMount(): void {
		this.setState({loading: true});
		let query = firestoreDb
			.collection("projects")
			.where('user.uid', '==', this.props.user?.uid)
			.orderBy('title', 'asc')

		query.get()
			.then(result => {
				console.log('Retrieved documents from firestore', result)
				const docs = result.empty ? [] : convertFromFirestoreToDocuments(result.docs)
				this.setState({
					projects: docs as any[],
					loading: false,
				})
			})
			.catch(err => {
				console.error('loadData', err)
			})
	}

	render(): JSX.Element {
		const {projects} = this.state;

		if (this.state.loading) {
			return <LoadingIndicator/>
		}

		return (
			<div className="wrapper">
				<div className="main">
					<div className="section section-white">
						<Container>
							<Grid container={true}>
								<Grid item={true} className="ml-auto mr-auto text-center title" md={12}>
									<h1>Projects</h1>

									<TableContainer>
										<Table className="table">
											<TableHead>
												<TableRow>
													<TableCell>Title</TableCell>
													<TableCell>Is Active</TableCell>
													<TableCell>Total Duration</TableCell>
													<TableCell>Language</TableCell>
													<TableCell/>
													<TableCell/>
													<TableCell/>
													<TableCell/>
												</TableRow>
											</TableHead>

											<TableBody>
												{projects && projects.map((project, idx) => {
													return (
														<TableRow key={idx}>
															<TableCell>{project.title}</TableCell>
															<TableCell>
																{project.active && <Translate id="Yes"/>}
																{!project.active && <Translate id="No"/>}
															</TableCell>
															<TableCell>{project.totalDuration}</TableCell>
															<TableCell>{project.locale}</TableCell>
															<TableCell>
																<LocaleLink to={`protected/time-tracking/${project.slug}`}>
																	<Translate id="Timesheet"/>
																</LocaleLink>
															</TableCell>
															<TableCell>
																<LocaleLink to={`protected/projects/${project.slug}`}>
																	<Translate id="Edit"/>
																</LocaleLink>
															</TableCell>
															<TableCell>
																<a data-ng-confirm-click="deleteProject(project.slug)"
																	 data-ng-confirm-message="Are you sure?">Destroy</a>
															</TableCell>
														</TableRow>
													)
												})}
											</TableBody>
										</Table>
									</TableContainer>

									<br/>

									<Link to="/protected/projects/new">
										<Translate id="protected.New Project"/>
									</Link>
								</Grid>
							</Grid>
						</Container>
					</div>
				</div>
			</div>
		)
	}
}

export default withBootstrap(ProtectedProjectsIndex)
