import {UserInfo} from "./Models";

export interface Content {
	domain: string,
	locale: string,
	slug: string,
	title: string,
	image: string | undefined,
	imageCopyright: string | undefined,
	shortDescription: string,
	parts: ContentPart[] | null,
	category: string | undefined,
	tags: Array<string | null> | null,
	contentVisibility: ContentVisibility,
	visibility: Visibility,
	user: UserInfo,
	createdAt: string,
	updatedAt: string,

	// Available after retrieval
	imageUrl: string,
}

export interface ContentPart {
	type: ContentPartType,
	title: string,
	body: string,
	image: string,
	imageCopyright: string,
	align: ContentAlign,

	// Available after retrieval
	imageUrl: string,
}

export enum ContentPartType {
	HTML = "HTML",
	MARKDOWN = "MARKDOWN",
	WIDGET = "WIDGET",
}


export enum ContentAlign {
	TEXT_CENTER = "TEXT_CENTER",
	TEXT_LEFT = "TEXT_LEFT",
	TEXT_RIGHT = "TEXT_RIGHT",
}

export enum ContentVisibility {
	HIDE = "HIDE",
	LIST = "LIST",
}

export enum Visibility {
	PUBLIC = 'public',
	PROTECTED = 'protected',
	ADMIN = 'admin',
}
