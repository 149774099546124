import * as React from "react";
import {Translate} from "react-localize-redux";
import {RouteComponentProps} from "react-router-dom";
import {Clipping, Dictionary, TParams} from "../../Models";
import {Button, Checkbox, Container, FormControlLabel, FormGroup, Grid} from "@material-ui/core";
import TemplateHelper from "../../Components/Common/TemplateHelper";
import {slugify} from "../../Components/Common/Utils";
import {Label} from "@material-ui/icons";
import OdaniaEditor from "../../Components/Editor/OdaniaEditor";
import PrintError from "../../Components/Common/PrintError";
import {BootstrapContextProps} from "../../Components/Bootstrap/BootstrapContext";
import {firestoreDb} from "../../Components/Firebase";
import {withBootstrap} from "../../Components/Bootstrap/withBootstrap";
import {Visibility} from "../../CommonModels";

interface InterfaceState {
	errors: Dictionary,
	loading: boolean,
	clipping: Clipping,
}

class ProtectedClippingsEdit extends React.Component<BootstrapContextProps & RouteComponentProps<TParams>, InterfaceState> {
	state = {
		errors: {} as Dictionary,
		clipping: {
			title: '',
			url: '',
			imageUrl: '',
			tags: [] as string[],
			visibility: Visibility.PUBLIC,
		} as Clipping,
		loading: true,
	};

	constructor(props: BootstrapContextProps & RouteComponentProps<TParams>) {
		super(props)

		this.saveClipping = this.saveClipping.bind(this)
	}

	loadData(): void {
		const slug = this.props.match.params.slug
		if (slug === 'new') {
			this.setState({loading: false})
			return
		}

		this.setState({loading: true})
		firestoreDb
			.collection(`clippings`)
			.doc(slug)
			.get()
			.then(result => {
				const doc = result.exists ? {} as Clipping : result.data()
				console.log('Loaded clipping', doc)
				this.setState({clipping: doc as Clipping, loading: false})
			})
	}

	componentDidMount(): void {
		this.loadData();
	}

	componentDidUpdate(prevProps: Readonly<BootstrapContextProps & RouteComponentProps<TParams>>) {
		if (prevProps.match.params.slug !== this.props.match.params.slug) {
			this.loadData()
		}
	}

	saveClipping(): void {
		const {clipping} = this.state
		console.log('saveClipping', clipping)
		clipping.slug = slugify(clipping.title)
		clipping.user = {
			uid: this.props.user?.uid || '',
			name: this.props.user?.displayName || '',
			image: this.props.user?.photoURL || '',
		}
		firestoreDb.collection(`clippings`)
			.doc(clipping.slug)
			.set(clipping)
			.then(() => {
				console.log('Saved')
			})
	}

	render(): JSX.Element {
		const {clipping} = this.state;

		return (
			<div className="wrapper">
				<div className="main">
					<div className="section section-white">
						<Container>
							<Grid container={true}>
								<Grid item={true} className="ml-auto mr-auto text-center title" md={12}>
									<form onSubmit={TemplateHelper.handleSubmit} className="form-horizontal" role="form">
										<div className="row-fluid">
											<div className="col-md-9">
												<h1>Editing Clipping</h1>

												<div className="form-group">
													<label className="col-sm-2 control-label">Title</label>

													<div className="col-sm-10">
														<input type="text" value={clipping.title}
																	 onChange={(event) =>
																		 TemplateHelper.updateNestedSelectField(this, 'clipping', 'title', event.target.value)}
																	 placeholder="Title" className="form-control"/>
														<PrintError errors={this.state.errors['title']}/>
													</div>
												</div>

												<div className="form-group">
													<label className="col-sm-2 control-label">Url</label>

													<div className="col-sm-10">
														<input type="text" value={clipping.url}
																	 onChange={(event) =>
																		 TemplateHelper.updateNestedSelectField(this, 'clipping', 'url', event.target.value)}
																	 placeholder="http://www.example.com"
																	 className="form-control"/>
														<PrintError errors={this.state.errors['url']}/>
													</div>
												</div>

												<div className="form-group">
													<label className="col-sm-2 control-label">Image Url</label>

													<div className="col-sm-10">
														<input type="text" value={clipping.imageUrl}
																	 onChange={(event) =>
																		 TemplateHelper.updateNestedSelectField(this, 'clipping', 'imageUrl', event.target.value)}
																	 placeholder="http://www.example.com/image.png"
																	 className="form-control"/>
														<PrintError errors={this.state.errors['image_url']}/>
													</div>
												</div>

												<div className="form-group">
													<label className="col-sm-2 control-label">Description</label>

													<div className="col-sm-10">
														<OdaniaEditor content={clipping.description}
																					updateContent={(content) => TemplateHelper.updateNestedSelectField(this, 'clipping', 'description', content)}/>
														<PrintError errors={this.state.errors['description']}/>
													</div>
												</div>

												<div className="form-group">
													<label className="col-sm-2 control-label">Tags</label>

													<div className="col-sm-10">
														<input type="text" value={clipping.tags} className="form-control"/>
														<PrintError errors={this.state.errors['tags']}/>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												{Object.keys(this.state.errors).length === 0 &&
												<span style={{color: 'red'}}><Translate id="general.Please correct the errors"/></span>}

												<div className="well">
													<h3>Title</h3>

													<Button onClick={this.saveClipping} className="btn btn-primary"><Translate
														id="Save"/>
													</Button>
												</div>

												<div className="form-group">
													<label className="col-sm-2 control-label">Public</label>

													<div className="col-sm-10">
														<FormGroup>
															<h4>
																<Translate id="Visibility"/>
															</h4>

															{Object.values(Visibility).map((visibility, visibilityIdx) => {
																return (
																	<FormControlLabel
																		key={visibilityIdx}
																		style={{paddingRight: 30}}
																		control={<Checkbox radioGroup="visibility"
																											 checked={clipping.visibility === visibility}
																											 onChange={() =>
																												 TemplateHelper.updateNestedSelectField(this, 'clipping', 'visibility', visibility)}
																		/>}
																		label={visibility}
																	/>
																)
															})}
														</FormGroup>
													</div>
												</div>
											</div>
										</div>
									</form>
								</Grid>
							</Grid>
						</Container>
					</div>
				</div>
			</div>
		)
	}
}

export default withBootstrap(ProtectedClippingsEdit)

