import React from "react";
import {AccountTree, Attachment, Home, Schedule} from "@material-ui/icons";
import {AppBar, IconButton, Toolbar} from "@material-ui/core";
import {BootstrapContextProps} from "../Bootstrap/BootstrapContext";
import {withBootstrap} from "../Bootstrap/withBootstrap";

class Header extends React.Component<BootstrapContextProps, any> {
	render() {
		const {locale} = this.props

		return (
			<AppBar position="static">
				<Toolbar>
					<IconButton edge="start" color="inherit" aria-label="home" href={`/${locale}/protected`}>
						<Home fontSize="large"/>
					</IconButton>
					<IconButton edge="start" color="inherit" aria-label="home" href={`/${locale}/protected/projects`}>
						<AccountTree fontSize="large"/>
					</IconButton>
					<IconButton edge="start" color="inherit" aria-label="home" href={`/${locale}/protected/time-tracking`}>
						<Schedule fontSize="large"/>
					</IconButton>
					<IconButton edge="start" color="inherit" aria-label="home" href={`/${locale}/protected/clippings`}>
						<Attachment fontSize="large"/>
					</IconButton>
				</Toolbar>
			</AppBar>
		)
	}
}

export default withBootstrap(Header)
